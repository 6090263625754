import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { faApple, faGooglePlay, faPlaystation, faSteam, faXbox, faFacebookSquare, faInstagram, faVk, faYoutubeSquare, faTiktok, faDiscord, faYandexInternational, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { faCrazyGames, faNintendoSwitch, faRuStore } from './icons';

export type Service = {
    name: string,
    icon: IconDefinition,
    registration?: string,
    download?: string,
    link?: string
}

export const services = {
    appStore: {
        name: "app-store",
        icon: faApple,
        registration: "/images/registration/app-store.png",
        download: "/images/download/app-store.png"
    },
    googlePlay: {
        name: "google-play",
        icon: faGooglePlay,
        registration: "/images/registration/google-play.png",
        download: "/images/download/google-play.png"
    },
    steam: {
        name: "steam",
        icon: faSteam,
        registration: "/images/registration/steam.png"
    },
    xboxLive: {
        name: "xbox-live",
        icon: faXbox
    },
    psStore: {
        name: "ps-store",
        icon: faPlaystation
    },
    nintendoSwitch: {
        name: "nintendo-switch",
        icon: faNintendoSwitch
    },
    yandexGames: {
        name: "yandex-games",
        icon: faYandexInternational
    },
    crazyGames: {
        name: "crazy-games",
        icon: faCrazyGames
    },
    ruStore: {
        name: "ru-store",
        icon: faRuStore
    },
    vk: {
        name: "vk",
        icon: faVk,
        link: "https://vk.com/euphoriagames"
    },
    facebook: {
        name: "facebook",
        icon: faFacebookSquare,
        link: "https://www.facebook.com/EuphoriaHorrorGames/"
    },
    instagram: {
        name: "instagram",
        icon: faInstagram,
        link: "https://instagram.com/euphoriahorrorgames/"
    },
    youtube: {
        name: "youtube",
        icon: faYoutubeSquare,
        link: "https://youtube.com/channel/UC5xsTxTysKUj51hVn_1tM9Q"
    },
    tiktok: {
        name: "tiktok",
        icon: faTiktok,
        link: "https://www.tiktok.com/@euphoriahorrorgames"
    },
    discord: {
        name: "discord",
        icon: faDiscord,
        link: "https://discord.gg/3TujkpPeVa"
    },
    telegram: {
        name: "telegram",
        icon: faTelegramPlane,
        link: "https://t.me/euphoriagamesstudio"
    }
}

type Social = {
    name: string,
    icon: IconDefinition,
    link: string
}

export const socials: Social[] = [
    services.tiktok,
    services.discord,
    services.vk,
    // services.facebook,
    // services.instagram,
    services.youtube,
    services.telegram
]
