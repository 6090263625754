import { ProjectStoreLinks } from '../../constants/projects';
import { Caption } from './Caption';
import { strings } from '../../constants/strings';
import { services } from '../../constants/services';
import { ServiceIconLink } from './ServiceIconLink';

type StoreLinksProps = {
    links: ProjectStoreLinks,
};

export const StoreLinks = (props: StoreLinksProps) => {
    const { googlePlay, appStore, steam, xboxLive, psStore, nintendoSwitch, yandexGames, crazyGames, ruStore } = props.links
    return (
        <div className="d-flex align-items-center">
            <Caption>{googlePlay || appStore || steam ? strings.storeLinksHeader : strings.soonHeader}</Caption>
            <div className="d-flex fs-3">
                {googlePlay && <ServiceIconLink href={googlePlay} service={services.googlePlay} />}
                {appStore && <ServiceIconLink href={appStore} service={services.appStore} />}
                {steam && <ServiceIconLink href={steam} service={services.steam} />}
                {xboxLive && <ServiceIconLink href={xboxLive} service={services.xboxLive} />}
                {psStore && <ServiceIconLink href={psStore} service={services.psStore} />}
                {nintendoSwitch && <ServiceIconLink href={nintendoSwitch} service={services.nintendoSwitch} />}
                {yandexGames && <ServiceIconLink href={yandexGames} service={services.yandexGames} />}
                {crazyGames && <ServiceIconLink href={crazyGames} service={services.crazyGames} />}
                {ruStore && <ServiceIconLink href={ruStore} service={services.ruStore} />}
            </div>
        </div>
    );
};
