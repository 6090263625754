import { strings } from "./strings";

export type Project = {
    path: string,
    title: string,
    rating?: string,
    installs?: string,
    description: string,
    features: string[],
    links: ProjectLinks,
    cover: string,
    screenshots: string[],
    hasOffer?: boolean
};

export type ProjectLinks = {
    stores?: ProjectStoreLinks,
    registration?: ProgectRegistrationLinks,
    youtube?: string,
};

export type ProjectStoreLinks = {
    googlePlay?: string,
    appStore?: string,
    xboxLive?: string,
    psStore?: string,
    steam?: string,
    nintendoSwitch?: string,
    yandexGames?: string,
    crazyGames?: string,
    ruStore?: string
}

export type ProgectRegistrationLinks = {
    googlePlay?: string,
    appStore?: string,
    steam?: string,
}

export const projects: () => Project[] = () => [
    {
        path: "mist",
        title: "Mist",
        rating: "4.7/5",
        installs: "200K+",
        description: strings.mistDescription,
        features: strings.mistFeatures,
        links: {
            stores: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.eg.mist.horror.idle.rpg",
                appStore: "https://apps.apple.com/app/mist-horror-idle-survival-rpg/id6499312165"
            },
            youtube: "https://www.youtube.com/embed/7wyC_WNRqfI"
        },
        cover: "images/mist/cover.png",
        screenshots: [
            "images/mist/1.jpg",
            "images/mist/2.jpg",
            "images/mist/3.jpg",
            "images/mist/4.jpg"
        ]
    },
    {
        path: "prey",
        title: "911: Prey",
        rating: "4.4/5",
        installs: "1M+",
        description: strings.preyDescription,
        features: strings.preyFeatures,
        links: {
            stores: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.eg.horror911.prey.escape",
                appStore: "https://apps.apple.com/app/911-prey-horror-escape-game/id6450622838",
                steam: "https://store.steampowered.com/app/2537120/911"
            },
            youtube: "https://www.youtube.com/embed/aLVOjRtNdDE"
        },
        cover: "images/prey/cover.png",
        screenshots: [
            "images/prey/1.jpg",
            "images/prey/2.jpg",
            "images/prey/3.jpg",
            "images/prey/4.jpg",
            "images/prey/5.jpg"
        ]
    },
    {
        path: "cannibal",
        title: "911: Cannibal",
        rating: "4.4/5",
        installs: "1M+",
        description: strings.cannibalDescription,
        features: strings.cannibalFeatures,
        links: {
            stores: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.eg.horror911.cannibal.escape",
                appStore: "https://apps.apple.com/us/app/911-cannibal-horror-escape/id1662065581",
                steam: "https://store.steampowered.com/app/2366080",
                nintendoSwitch: "https://www.nintendo.com/store/products/911-cannibal-switch/",
                crazyGames: "https://www.crazygames.com/game/911-cannibal?bypassCache=3xzbi"
            },
            youtube: "https://www.youtube.com/embed/lH8-HJ1f_BE"
        },
        cover: "images/cannibal/cover.png",
        screenshots: [
            "images/cannibal/1.jpg",
            "images/cannibal/2.jpg",
            "images/cannibal/3.jpg",
            "images/cannibal/4.jpg",
            "images/cannibal/5.jpg"
        ]
    },
    {
        path: "ht2",
        title: "Horror Tale 2: Samantha",
        rating: "4.8/5",
        installs: "2M+",
        description: strings.ht2Description,
        features: strings.ht2Features,
        links: {
            stores: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.eg.horrortale2",
                appStore: "https://apps.apple.com/app/id1645041459",
                steam: "https://store.steampowered.com/app/2258910/Horror_Tale_2",
                xboxLive: "https://www.xbox.com/games/store/horror-tale-2-samantha/9nxz9856ln2r",
                psStore: "https://store.playstation.com/en-us/product/UP6557-CUSA43464_00-0223914489038284",
                nintendoSwitch: "https://www.nintendo.com/store/products/horror-tale-2-samantha-switch/",
                yandexGames: "https://yandex.ru/games/app/215962",
                crazyGames: "https://www.crazygames.com/game/horror-tale-2-samantha"
            },
            youtube: "https://www.youtube.com/embed/_zrfCJfK894"
        },
        cover: "images/ht2/cover.png",
        screenshots: [
            "images/ht2/1.jpg",
            "images/ht2/2.jpg",
            "images/ht2/3.jpg",
            "images/ht2/4.jpg",
            "images/ht2/5.jpg"
        ]
    },
    {
        path: "ht1",
        title: "Horror Tale 1: Kidnapper",
        rating: "4.8/5",
        installs: "3M+",
        description: strings.ht1Description,
        features: strings.ht1Features,
        links: {
            stores: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.eg.horrortale",
                appStore: "https://apps.apple.com/app/id1619069201",
                steam: "https://store.steampowered.com/app/2129150/Horror_Tale_1_Kidnapper",
                xboxLive: "https://www.xbox.com/games/store/horror-tale-1-kidnapper/9mwwqzqlx2s5",
                psStore: "https://store.playstation.com/product/UP6557-CUSA37795_00-0664892835727675",
                nintendoSwitch: "https://www.nintendo.com/store/products/horror-tale-1-kidnapper-switch/",
                yandexGames: "https://yandex.ru/games/app/212444",
                crazyGames: "https://www.crazygames.com/game/horror-tale-kidnapper"
            },
            youtube: "https://www.youtube.com/embed/GVLdD4ZFqVw"
        },
        cover: "images/ht1/cover.png",
        screenshots: [
            "images/ht1/1.jpg",
            "images/ht1/2.jpg",
            "images/ht1/3.jpg",
            "images/ht1/4.jpg",
            "images/ht1/5.jpg"
        ]
    },
    {
        path: "mimicry",
        title: "Mimicry",
        rating: "4.4/5",
        installs: "21M+",
        description: strings.mimicryDescription,
        features: strings.mimicryFeatures,
        hasOffer: strings.getLanguage() === "ru",
        links: {
            stores: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.eg.mimicry.horror.online",
                appStore: "https://apps.apple.com/app/mimicry-online-horror/id1591389424",
                steam: "https://store.steampowered.com/app/1804170/Mimicry/"
            },
            youtube: "https://www.youtube.com/embed/360QHN6R-Bg"
        },
        cover: "images/mimicry/cover.png",
        screenshots: [
            "images/mimicry/1.jpg",
            "images/mimicry/2.jpg",
            "images/mimicry/3.jpg",
            "images/mimicry/4.jpg",
            "images/mimicry/5.jpg"
        ]
    },
    {
        path: "dp2",
        title: "Death Park 2",
        rating: "4.7/5",
        installs: "26M+",
        description: strings.dp2Description,
        features: strings.dp2Features,
        links: {
            stores: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.eg.deathpark2",
                appStore: "https://apps.apple.com/ru/app/death-park-2-scary-clown-game/id1532887006",
                steam: "https://store.steampowered.com/app/1525890/Death_Park_2/",
                xboxLive: "https://www.xbox.com/games/store/death-park-2/9nqw90pmtvjm",
                psStore: "https://store.playstation.com/product/UP6557-CUSA29027_00-0250160560867216",
                nintendoSwitch: "https://www.nintendo.com/store/products/death-park-2-switch/",
                ruStore: "https://www.rustore.ru/catalog/app/com.eg.deathpark2"
            },
            youtube: "https://www.youtube.com/embed/R8U8bvyx2T8"
        },
        cover: "images/dp2/cover.png",
        screenshots: [
            "images/dp2/1.jpg",
            "images/dp2/2.jpg",
            "images/dp2/3.jpg",
            "images/dp2/4.jpg",
            "images/dp2/5.jpg",
            "images/dp2/6.jpg"
        ]
    },
    {
        path: "a88",
        title: "Antarctica 88",
        rating: "4.6/5",
        installs: "14M+",
        description: strings.a88Description,
        features: strings.a88Features,
        links: {
            stores: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.eg.antarctica88",
                appStore: "https://apps.apple.com/ru/app/antarctica-88-scary-horror/id1518997118",
                steam: "https://store.steampowered.com/app/1383630/Antarctica_88/",
                xboxLive: "https://www.xbox.com/games/store/antarctica-88/9njvnjsjszp2",
                psStore: "https://store.playstation.com/product/UP6557-CUSA30679_00-6909278303269399",
                nintendoSwitch: "https://www.nintendo.com/store/products/antarctica-88-switch",
                crazyGames: "https://www.crazygames.com/game/antarctica-88",
                ruStore: "https://www.rustore.ru/catalog/app/com.eg.antarctica88"
            },
            youtube: "https://www.youtube.com/embed/UjiHpiE4ZfY"
        },
        cover: "images/a88/cover.png",
        screenshots: [
            "images/a88/1.jpg",
            "images/a88/2.jpg",
            "images/a88/3.jpg",
            "images/a88/4.jpg",
            "images/a88/5.jpg",
            "images/a88/6.jpg"
        ]
    },
    {
        path: "dp",
        title: "Death Park",
        rating: "4.5/5",
        installs: "36M+",
        description: strings.dpDescription,
        features: strings.dpFeatures,
        links: {
            stores: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.eg.deathpark",
                appStore: "https://apps.apple.com/ru/app/death-park-scary-clown-horror/id1481457207",
                steam: "https://store.steampowered.com/app/1186030/Death_Park/",
                xboxLive: "https://www.xbox.com/games/store/death-park/9n4gd21wn021",
                psStore: "https://store.playstation.com/product/UP6557-CUSA29025_00-6134613661494424",
                nintendoSwitch: "https://www.nintendo.com/store/products/death-park-switch/",
                ruStore: "https://www.rustore.ru/catalog/app/com.eg.deathpark"
            },
            youtube: "https://www.youtube.com/embed/aWdGLEwLdBg"
        },
        cover: "images/dp/cover.png",
        screenshots: [
            "images/dp/1.jpg",
            "images/dp/2.jpg",
            "images/dp/3.jpg",
            "images/dp/4.jpg",
            "images/dp/5.jpg",
            "images/dp/6.jpg"
        ]
    }
]
